.header {
  position: relative;
  background-color: rgb(255, 255, 255);
  z-index: 1000;
  width: 100%;
  border-bottom: 1px solid #ddd;
  /* transition: all 600ms ease; */
}

.sticky {
  position: relative;
  /* transform: translate(0, -200px); */
  top: -100px;
}

.inView {
  position: fixed;
  top: 0px;
  transform: translate(0, 0);
  transition: all 850ms ease;
}

.navbar-brand {
  font-size: 1.5rem !important;
  color: black;
  font-family: 'Roboto', sans-serif;
  transition: all ease 1s;
  position: relative;
}

.logo-hovered {
  display: none;
}
.navbar-brand:hover .logo-hovered {
  display: inline-block;
}
.navbar-brand:hover .logo-default {
  display: none;
}

.logo {
  max-height: 50px;
  padding-right: 6px;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity .1s ease-in-out;
  vertical-align: middle;
}

.navbar-nav .nav-item {
  font-weight: 400;
  padding-left: 20px;
  font-size: 18px;
  color: black;
  font-family: "Big Shoulders Text";
}

/* MEGA MENU STYLE
********************************/
.mega-menu {
  top: 60px !important;
  padding: 10px 10px 10px 10px !important;
  width: 90%;
  border-radius: 0;
  margin-top: 0px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mega-menu li {
  margin-left: auto !important;
  margin-right: auto !important;
  display: inline-block;
  font-size: 0.94rem;
  vertical-align: top;
  width: 100%;
}

.mega-menu li.mega-menu-column {
  margin: 10px;
  width: 190px;
}
.dropdown-menu {
  font-family: sans-serif;
  font-size: 11pt;
  margin-top: -10px !important;
}

/* To open dropdown menu when hover */
.navbar-nav li:hover > .dropdown-menu {
  display: block;
}

.mega-menu .nav-header {
  padding: 0 !important;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 9pt;
  font-weight: bold;
}

.mega-menu .nav-header:hover {
  color: rgba(245, 34, 34, 0.808);
}

.mega-menu img {
  padding-bottom: 10px;
}

.mega-menu a {
  color: black;
  font-family: sans-serif;
  font-size: 9pt;
}

.mega-menu a::before {
  content: "► ";
}

.mega-menu a:hover {
  color: rgba(245, 34, 34, 0.808);
  transition: all ease 200ms;
  text-decoration: none;
}

.mega-menu .mega-item:hover {
  padding-left: 3px;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .active .nav-link {
  /* background: #262626ec; */
  color: rgba(245, 34, 34, 0.808) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: black !important;
  padding: 15px 15px;
  transition: 550ms;
}

.nav-link-ltr::before {
  width: 0%;
  bottom: 10px;
  position: relative;
}

.nav-link-ltr:hover::before {
  width: 80%;
}
.nav-link {
  position: relative !important;
}
.navbar-light .navbar-nav .nav-link::before {
  transition: 500ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: rgba(245, 34, 34, 0.555);
}

.menu-img {
  width: 150px;
  height: 120px;
  object-fit: cover;
}

/* Animation for dropdown */

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
