.side-drawer {
  height: 100%;
  background-color: black;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 65%;
  max-width: 400px;
  z-index: 2000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  overflow-y: auto;
}
.side-drawer-brand {
  padding: 20px;
  display: block;
  text-align: center;
  cursor: pointer;
}
.side-drawer-logo {
  width: 50%;
}
.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  width: 100%;
  margin-top: 190px;
  margin-right: auto;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 10px;
  display: block;
}

.side-drawer li {
  margin: 0.5rem 0;
  padding-left: 0;
  display: list-item;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
}

@media (min-width: 992px) {
  .side-drawer {
    display: none;
  }
}
.sidebar-nav {
  position: absolute;
  top: 0;
  width: 220px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 100%;
}
.sub-list {
  display: table !important;
}
.btn {
  display: inline-block !important;
}

.sidebar-nav li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 3px;
  background-color: #1c1c1c;
  -webkit-transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -ms-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
}
.sidebar-nav li:first-child a {
  color: #fff;
  background-color: #1a1a1a;
}
.sidebar-nav li:nth-child(2):before {
  background-color: #ec1b5a;
}
.sidebar-nav li:nth-child(3):before {
  background-color: #79aefe;
}
.sidebar-nav li:nth-child(4):before {
  background-color: #314190;
}
.sidebar-nav li:nth-child(5):before {
  background-color: #279636;
}
.sidebar-nav li:nth-child(6):before {
  background-color: #7d5d81;
}
.sidebar-nav li:nth-child(7):before {
  background-color: #ead24c;
}
.sidebar-nav li:nth-child(8):before {
  background-color: #2d2366;
}
.sidebar-nav li:nth-child(9):before {
  background-color: #35acdf;
}
.sidebar-nav li:hover:before,
.sidebar-nav li.show:hover:before {
  width: 100%;
  -webkit-transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -ms-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
}

.sidebar-nav li a {
  width: 100%;
  display: block;
  color: #ddd;
  text-decoration: none;
  padding: 10px 15px 10px 30px;
}

.sidebar-nav li a:hover,
.sidebar-nav li a:active,
.sidebar-nav li a:focus,
.sidebar-nav li.show a:hover,
.sidebar-nav li.show a:active,
.sidebar-nav li.show a:focus {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 20px;
  line-height: 44px;
}

.sidebar-nav .dropdown-menu {
  position: relative !important;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: #222;
  box-shadow: none;
  display: none;
  transform: none !important;
}

.dropdown-menu.collapsing {
  display: block;
  transition: height .4s ease;
  height: 0;
  overflow: hidden;
}

.dropdown-menu.show {
  display: block !important;
}

.btn-secondary {
  background-color: #1c1c1c00 !important;
  border: none !important;
  padding: 2px 2px 2px 2px;
  /* width: 5%; */
  /* margin-right: 0px; */
}
.btn-secondary:focus {
  /* color: #1c1c1c00 !important; */
  box-shadow: none;
}

.btn-group {
  width: 100%;
}

.sub {
  /* background-color: white !important; */
  padding-left: 20px !important;
  margin-top: 10px !important;
}