.banner-img {
  filter: blur(1px);
}

.overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #131212da;
  opacity: 0.55;
  z-index: 1;
}

.carousel-caption {
  z-index: 2 !important;
  top: 28%;
  text-align: left !important;
}

.carousel-title {
  width: 60%;
  font-size: 4.4rem;
  font-weight: 800;
  text-shadow: rgba(148, 148, 148, 0.335) 1px 1px 1px;
  -webkit-font-smoothing: antialiased;
}

.carousel-body {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 80%;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 2.4rem;
  font-size: 2rem !important;
  color: white;
}

@media (max-width:1600px) {

  .carousel-caption {
    top: 25%;
  }
}

@media (max-width:1500px) {

  .carousel-caption {
    top: 20%;
  }
  .carousel-title {
    font-size: 4rem;
  }
}

@media (max-width:1200px) {

  .carousel-caption {
    top: 20%;
  }
  .carousel-title {
    font-size: 3.5rem;
  }
  .carousel-body {
    font-size: 1.6rem !important;
  }
}

@media (max-width:1100px) {

  .carousel-caption {
    top: 10%;
  }

  .carousel-title {
    width: 65%;
    font-size: 3rem;
  }

  .carousel-body {
    width: 85%;
    font-size: 1.6rem !important;
  }
}


@media (max-width:950px) {
  .carousel-caption {
    top: 10%;
  }

  .carousel-title {
    width: 65%;
    font-size: 2rem;
  }

  .carousel-body {
    width: 85%;
    font-size: 1.2rem !important;
  }
}


@media (max-width:770px) {

  .carousel-caption {
    top: 20%;
    text-align: center !important;
  }

  .carousel-title {
    width: 100%;
    font-size: 2rem;
    text-align: center !important;
  }

  .carousel-body {
    display: none;
  }
}


@media (max-width:500px) {

  .carousel-caption {
    top: 15%;
    text-align: center !important;
  }

  .carousel-title {
    width: 100%;
    font-size: 1.6rem;
    text-align: center !important;
  }

  .carousel-body {
    display: none;
  }
}