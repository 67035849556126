.toolbarContainer {
    height: 77px
}

.content {
    padding-top: 0px;
}

.prevent-scroll {
    overflow: hidden;
}