@import url(https://fonts.googleapis.com/css2?family=Lexend+Mega&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@500&display=swap);
body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* https://animate.style */
.animated-fadeInDown {
  -webkit-animation: fadeInDown !important;
          animation: fadeInDown !important;
  -webkit-animation-duration: 2.5s !important;
          animation-duration: 2.5s !important;
}


.animated-fadeInUp {
  -webkit-animation: fadeInUp !important;
          animation: fadeInUp !important;
  -webkit-animation-duration: 3.5s !important;
          animation-duration: 3.5s !important;
}

.animated-slideInLeft {
  -webkit-animation: slideInLeft !important;
          animation: slideInLeft !important;
  -webkit-animation-duration: 2.5s !important;
          animation-duration: 2.5s !important;
}

.animated-slideInRight {
  -webkit-animation: slideInRight !important;
          animation: slideInRight !important;
  -webkit-animation-duration: 2.5s !important;
          animation-duration: 2.5s !important;
}

.animated-slideInUp {
  -webkit-animation: slideInUp !important;
          animation: slideInUp !important;
  -webkit-animation-duration: 3.5s !important;
          animation-duration: 3.5s !important;
}

.animated-slideInDown {
  -webkit-animation: slideInDown !important;
          animation: slideInDown !important;
  -webkit-animation-duration: 2.5s !important;
          animation-duration: 2.5s !important;
}
p {
  font-size: 15px;
  line-height: 22px;
  color: #252525;
  font-weight: 400;
}
.page-title {
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
}

iframe {
  max-width: 100%;
}

.dropdown-menu {
  min-width: 13rem !important;
}
.form-control {
  background-color: #f7f7f9 !important;
}
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #e94d4dbb;
  font-size: 5px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toolbarContainer {
    height: 77px
}

.content {
    padding-top: 0px;
}

.prevent-scroll {
    overflow: hidden;
}
.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1500;
}

.header {
  position: relative;
  background-color: rgb(255, 255, 255);
  z-index: 1000;
  width: 100%;
  border-bottom: 1px solid #ddd;
  /* transition: all 600ms ease; */
}

.sticky {
  position: relative;
  /* transform: translate(0, -200px); */
  top: -100px;
}

.inView {
  position: fixed;
  top: 0px;
  transform: translate(0, 0);
  transition: all 850ms ease;
}

.navbar-brand {
  font-size: 1.5rem !important;
  color: black;
  font-family: 'Roboto', sans-serif;
  transition: all ease 1s;
  position: relative;
}

.logo-hovered {
  display: none;
}
.navbar-brand:hover .logo-hovered {
  display: inline-block;
}
.navbar-brand:hover .logo-default {
  display: none;
}

.logo {
  max-height: 50px;
  padding-right: 6px;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity .1s ease-in-out;
  vertical-align: middle;
}

.navbar-nav .nav-item {
  font-weight: 400;
  padding-left: 20px;
  font-size: 18px;
  color: black;
  font-family: "Big Shoulders Text";
}

/* MEGA MENU STYLE
********************************/
.mega-menu {
  top: 60px !important;
  padding: 10px 10px 10px 10px !important;
  width: 90%;
  border-radius: 0;
  margin-top: 0px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mega-menu li {
  margin-left: auto !important;
  margin-right: auto !important;
  display: inline-block;
  font-size: 0.94rem;
  vertical-align: top;
  width: 100%;
}

.mega-menu li.mega-menu-column {
  margin: 10px;
  width: 190px;
}
.dropdown-menu {
  font-family: sans-serif;
  font-size: 11pt;
  margin-top: -10px !important;
}

/* To open dropdown menu when hover */
.navbar-nav li:hover > .dropdown-menu {
  display: block;
}

.mega-menu .nav-header {
  padding: 0 !important;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 9pt;
  font-weight: bold;
}

.mega-menu .nav-header:hover {
  color: rgba(245, 34, 34, 0.808);
}

.mega-menu img {
  padding-bottom: 10px;
}

.mega-menu a {
  color: black;
  font-family: sans-serif;
  font-size: 9pt;
}

.mega-menu a::before {
  content: "► ";
}

.mega-menu a:hover {
  color: rgba(245, 34, 34, 0.808);
  transition: all ease 200ms;
  text-decoration: none;
}

.mega-menu .mega-item:hover {
  padding-left: 3px;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .active .nav-link {
  /* background: #262626ec; */
  color: rgba(245, 34, 34, 0.808) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: black !important;
  padding: 15px 15px;
  transition: 550ms;
}

.nav-link-ltr::before {
  width: 0%;
  bottom: 10px;
  position: relative;
}

.nav-link-ltr:hover::before {
  width: 80%;
}
.nav-link {
  position: relative !important;
}
.navbar-light .navbar-nav .nav-link::before {
  transition: 500ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: rgba(245, 34, 34, 0.555);
}

.menu-img {
  width: 150px;
  height: 120px;
  object-fit: cover;
}

/* Animation for dropdown */

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

.side-drawer {
  height: 100%;
  background-color: black;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 65%;
  max-width: 400px;
  z-index: 2000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  overflow-y: auto;
}
.side-drawer-brand {
  padding: 20px;
  display: block;
  text-align: center;
  cursor: pointer;
}
.side-drawer-logo {
  width: 50%;
}
.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  width: 100%;
  margin-top: 190px;
  margin-right: auto;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 10px;
  display: block;
}

.side-drawer li {
  margin: 0.5rem 0;
  padding-left: 0;
  display: list-item;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
}

@media (min-width: 992px) {
  .side-drawer {
    display: none;
  }
}
.sidebar-nav {
  position: absolute;
  top: 0;
  width: 220px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 100%;
}
.sub-list {
  display: table !important;
}
.btn {
  display: inline-block !important;
}

.sidebar-nav li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 3px;
  background-color: #1c1c1c;
  transition: width 0.2s ease-in;
}
.sidebar-nav li:first-child a {
  color: #fff;
  background-color: #1a1a1a;
}
.sidebar-nav li:nth-child(2):before {
  background-color: #ec1b5a;
}
.sidebar-nav li:nth-child(3):before {
  background-color: #79aefe;
}
.sidebar-nav li:nth-child(4):before {
  background-color: #314190;
}
.sidebar-nav li:nth-child(5):before {
  background-color: #279636;
}
.sidebar-nav li:nth-child(6):before {
  background-color: #7d5d81;
}
.sidebar-nav li:nth-child(7):before {
  background-color: #ead24c;
}
.sidebar-nav li:nth-child(8):before {
  background-color: #2d2366;
}
.sidebar-nav li:nth-child(9):before {
  background-color: #35acdf;
}
.sidebar-nav li:hover:before,
.sidebar-nav li.show:hover:before {
  width: 100%;
  transition: width 0.2s ease-in;
}

.sidebar-nav li a {
  width: 100%;
  display: block;
  color: #ddd;
  text-decoration: none;
  padding: 10px 15px 10px 30px;
}

.sidebar-nav li a:hover,
.sidebar-nav li a:active,
.sidebar-nav li a:focus,
.sidebar-nav li.show a:hover,
.sidebar-nav li.show a:active,
.sidebar-nav li.show a:focus {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 20px;
  line-height: 44px;
}

.sidebar-nav .dropdown-menu {
  position: relative !important;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: #222;
  box-shadow: none;
  display: none;
  transform: none !important;
}

.dropdown-menu.collapsing {
  display: block;
  transition: height .4s ease;
  height: 0;
  overflow: hidden;
}

.dropdown-menu.show {
  display: block !important;
}

.btn-secondary {
  background-color: #1c1c1c00 !important;
  border: none !important;
  padding: 2px 2px 2px 2px;
  /* width: 5%; */
  /* margin-right: 0px; */
}
.btn-secondary:focus {
  /* color: #1c1c1c00 !important; */
  box-shadow: none;
}

.btn-group {
  width: 100%;
}

.sub {
  /* background-color: white !important; */
  padding-left: 20px !important;
  margin-top: 10px !important;
}
.header-container {
    background-color: rgba(0, 0, 0, 0.897) !important;
}

.header-container a {
    text-decoration: none;
    color: #8d8c8c;
    font-size: 8pt;
}

.header-container a:hover {
    text-decoration: none;
    color: white;
}

.fa-header i {
    color: #8d8c8c;
    font-size: 13px !important;
}

.fa-header i:hover {
    color: white;
}

.fa-header::before {
    content: "";
}

.list-contact {
    flex-direction: row !important;
}
.site-footer {
    background-color: #fbfbfbe3;
    width: 100%;
    z-index: 100;
    position: relative;
    color: #858585;
    padding: 10px;
    border-top: 2px solid #f4f4f4;
}
.site-footer .title {
    color: black;
    font-family: "Big Shoulders Text";
}

.footer-wrapper {
    padding-top: 25px;
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
}

.nav-item-wrapper {
    line-height: 1.6;
}

.nav-item-wrapper > p {
    font-size: 10pt;
    margin-bottom: 10px;
}
.footer-icon {
    padding-right: 5px;
    width: 20px;
}
.bullet {
    padding-left: 0;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
}
.bullet li {
    margin-bottom: 10px;
}
.footer-nav {
    color:#383434;
}
.footer-nav:hover{
    color: rgba(202, 28, 28, 0.74);
    font-weight: bolder;
    text-decoration: none;
}

.footer-logo {
    margin-bottom: 15px;
}
.icon-circle-group [class*="fa fa-"] {
    height: 30px;
    width: 30px;
    color: white;
    background-color: grey;
    border-radius: 15px;
    display: inline-block;
    line-height: 30px;
    margin: 10px 10px 10px 10px;
    font-size: 15px;
    text-align: center;
    transition: all ease 0.3s;
  }
  .fa-facebook:hover { 
    background-color: #3B5A9B; 
}
.fa-instagram:hover { 
    background-color: #ad0d35ea; 
}
.fa-linkedin:hover { 
    background-color: #4e9de7b4; 
}

@media (max-width: 768px) {
    .icon-circle-group {
        text-align: center;
        padding-top: 10px;
    }
    .icon-circle-group [class*="fa fa-"] {
        height: 30px;
        width: 30px;
        font-size: 15px;
        border-radius: 15px;
        line-height: 30px;
    }
    .contact-group {
        width: 100% !important;
    }
}
.copyright-wrapper {
    border-top: 1px solid #e2dbdb;
    background-color: #f2f2f4;
}
small {
    color: #878787;
}

.banner-img {
  -webkit-filter: blur(1px);
          filter: blur(1px);
}

.overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #131212da;
  opacity: 0.55;
  z-index: 1;
}

.carousel-caption {
  z-index: 2 !important;
  top: 28%;
  text-align: left !important;
}

.carousel-title {
  width: 60%;
  font-size: 4.4rem;
  font-weight: 800;
  text-shadow: rgba(148, 148, 148, 0.335) 1px 1px 1px;
  -webkit-font-smoothing: antialiased;
}

.carousel-body {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 80%;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 2.4rem;
  font-size: 2rem !important;
  color: white;
}

@media (max-width:1600px) {

  .carousel-caption {
    top: 25%;
  }
}

@media (max-width:1500px) {

  .carousel-caption {
    top: 20%;
  }
  .carousel-title {
    font-size: 4rem;
  }
}

@media (max-width:1200px) {

  .carousel-caption {
    top: 20%;
  }
  .carousel-title {
    font-size: 3.5rem;
  }
  .carousel-body {
    font-size: 1.6rem !important;
  }
}

@media (max-width:1100px) {

  .carousel-caption {
    top: 10%;
  }

  .carousel-title {
    width: 65%;
    font-size: 3rem;
  }

  .carousel-body {
    width: 85%;
    font-size: 1.6rem !important;
  }
}


@media (max-width:950px) {
  .carousel-caption {
    top: 10%;
  }

  .carousel-title {
    width: 65%;
    font-size: 2rem;
  }

  .carousel-body {
    width: 85%;
    font-size: 1.2rem !important;
  }
}


@media (max-width:770px) {

  .carousel-caption {
    top: 20%;
    text-align: center !important;
  }

  .carousel-title {
    width: 100%;
    font-size: 2rem;
    text-align: center !important;
  }

  .carousel-body {
    display: none;
  }
}


@media (max-width:500px) {

  .carousel-caption {
    top: 15%;
    text-align: center !important;
  }

  .carousel-title {
    width: 100%;
    font-size: 1.6rem;
    text-align: center !important;
  }

  .carousel-body {
    display: none;
  }
}
