.header-container {
    background-color: rgba(0, 0, 0, 0.897) !important;
}

.header-container a {
    text-decoration: none;
    color: #8d8c8c;
    font-size: 8pt;
}

.header-container a:hover {
    text-decoration: none;
    color: white;
}

.fa-header i {
    color: #8d8c8c;
    font-size: 13px !important;
}

.fa-header i:hover {
    color: white;
}

.fa-header::before {
    content: "";
}

.list-contact {
    flex-direction: row !important;
}