.site-footer {
    background-color: #fbfbfbe3;
    width: 100%;
    z-index: 100;
    position: relative;
    color: #858585;
    padding: 10px;
    border-top: 2px solid #f4f4f4;
}
.site-footer .title {
    color: black;
    font-family: "Big Shoulders Text";
}

.footer-wrapper {
    padding-top: 25px;
    width: max-content !important;
}

.nav-item-wrapper {
    line-height: 1.6;
}

.nav-item-wrapper > p {
    font-size: 10pt;
    margin-bottom: 10px;
}
.footer-icon {
    padding-right: 5px;
    width: 20px;
}
.bullet {
    padding-left: 0;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
}
.bullet li {
    margin-bottom: 10px;
}
.footer-nav {
    color:#383434;
}
.footer-nav:hover{
    color: rgba(202, 28, 28, 0.74);
    font-weight: bolder;
    text-decoration: none;
}

.footer-logo {
    margin-bottom: 15px;
}
.icon-circle-group [class*="fa fa-"] {
    height: 30px;
    width: 30px;
    color: white;
    background-color: grey;
    border-radius: 15px;
    display: inline-block;
    line-height: 30px;
    margin: 10px 10px 10px 10px;
    font-size: 15px;
    text-align: center;
    transition: all ease 0.3s;
  }
  .fa-facebook:hover { 
    background-color: #3B5A9B; 
}
.fa-instagram:hover { 
    background-color: #ad0d35ea; 
}
.fa-linkedin:hover { 
    background-color: #4e9de7b4; 
}

@media (max-width: 768px) {
    .icon-circle-group {
        text-align: center;
        padding-top: 10px;
    }
    .icon-circle-group [class*="fa fa-"] {
        height: 30px;
        width: 30px;
        font-size: 15px;
        border-radius: 15px;
        line-height: 30px;
    }
    .contact-group {
        width: 100% !important;
    }
}