body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* https://animate.style */
.animated-fadeInDown {
  animation: fadeInDown !important;
  animation-duration: 2.5s !important;
}


.animated-fadeInUp {
  animation: fadeInUp !important;
  animation-duration: 3.5s !important;
}

.animated-slideInLeft {
  animation: slideInLeft !important;
  animation-duration: 2.5s !important;
}

.animated-slideInRight {
  animation: slideInRight !important;
  animation-duration: 2.5s !important;
}

.animated-slideInUp {
  animation: slideInUp !important;
  animation-duration: 3.5s !important;
}

.animated-slideInDown {
  animation: slideInDown !important;
  animation-duration: 2.5s !important;
}